<template>
    <div class="stepsdemo-content">
        <i class="pi pi-fw pi-check" />
        <p>Confirmation Component Content via Child Route</p>
    </div>
</template>

<script>
export default {

}
</script>
